import st from './RatingByCountries.module.scss'
import Image from 'next/image'
import fifa_logo from '../../../public/img/fifa-logo.svg'
import { useGetRatingUefaQuery } from '../../../api/competitionsApi'
import { useTranslation } from 'next-i18next'
import React, { useEffect, useState } from 'react'
import { RatingUefaGroup } from '../../../api'
import clsx from 'clsx'
import { TableRating } from '../parts'
import { useAppDispatch } from '../../../hooks'
import { scrollToWindowTop } from '../../../utils'
import PreloadTopCountries from '../../../components/PreloaderTopCountries'
import { Button } from '../../../components/Button'
import { setMatchId } from '../../../features/match'

export const RatingByCountries = () => {
    const dispatch = useAppDispatch()
    const { i18n } = useTranslation()

    const ratingUefaQuery = useGetRatingUefaQuery({
        locale: i18n.language,
    })
    const {groups, ranking} = ratingUefaQuery.data?.data ?? {}

    const [search, setSearch] = useState('')
    const [groupSelected, setGroupSelected] = useState(0)

    // Reset filters
    useEffect(() => {
        setGroupSelected(0)
        setSearch('')
    }, [i18n.language])

    function onMatchSelect(id: number) {
        dispatch(setMatchId(id))
        scrollToWindowTop()
    }

    return (
        <div>
            <div className={st.header}>
                <div className={st.headerLogo}>
                    <Image
                        src={fifa_logo}
                        alt='FIFA'
                    />
                </div>
                рейтинг фифа
            </div>
            {ratingUefaQuery.isFetching && (
                <PreloadTopCountries type='section' />
            )}
            {!ratingUefaQuery.isFetching && (
                <>
                    <GroupTabs
                        groupsInitial={groups}
                        groupSelected={groupSelected}
                        onGroupSelect={setGroupSelected}
                    />
                    <Search
                        search={search}
                        onSearch={setSearch}
                    />
                    <TableRating
                        ranking={ranking}
                        groupSelected={groupSelected}
                        search={search}
                        onMatchSelect={onMatchSelect}
                    />
                </>
            )}
        </div>
    )
}

type GroupTabsProps = {
    groupsInitial: RatingUefaGroup[],
    groupSelected: number,
    onGroupSelect: (id: number) => void,
}

function GroupTabs({
    groupsInitial,
    groupSelected,
    onGroupSelect,
}: GroupTabsProps) {
    if (groupsInitial.length === 0) return null

    const groups = [
        {
            id: 0,
            title: 'ВСЕ АССОЦИАЦИИ',
        },
        ...groupsInitial,
    ]

    return (
        <div className={st.tabsContainer}>
            {(groups || []).map((item, index) => (
                <Button
                    key={index}
                    onClick={() => onGroupSelect(item.id)}
                    isActive={groupSelected === item.id}
                >
                    {item.title || item.id}
                </Button>
            ))}
        </div>
    )
}

type SearchProps = {
    search: string,
    onSearch: (v: string) => void,
}

function Search({search, onSearch}: SearchProps) {
    const isFilterActive = !!search
    return (
        <div className={clsx(st.searchContainer, isFilterActive && st.isActive)}>
            <IconSearch />
            <input
                type='text'
                placeholder='Фильтр по стране'
                value={search}
                onChange={(e) => onSearch(e.target.value)}
            />
            <button
                className={clsx(st.buttonReset, 'flex flex-align')}
                onClick={() => onSearch('')}
                disabled={!isFilterActive}
            >
                <IconReset />
            </button>
        </div>
    )
}

//@todo Replace svg with import
function IconSearch() {
    return (
        <svg
            className={st.iconSearch}
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M18.5 11.5C18.5 15.6421 15.1421 19 11 19C6.85786 19 3.5 15.6421 3.5 11.5C3.5 7.35786 6.85786 4 11 4C15.1421 4 18.5 7.35786 18.5 11.5ZM16.8498 18.3398C15.2766 19.6866 13.2333 20.5 11 20.5C6.02944 20.5 2 16.4706 2 11.5C2 6.52944 6.02944 2.5 11 2.5C15.9706 2.5 20 6.52944 20 11.5C20 13.7333 19.1866 15.7766 17.8398 17.3498C17.9218 17.3862 17.9986 17.438 18.0658 17.5052L22.0658 21.5052C22.3587 21.7981 22.3587 22.273 22.0658 22.5659C21.7729 22.8587 21.2981 22.8587 21.0052 22.5659L17.0052 18.5659C16.938 18.4986 16.8862 18.4218 16.8498 18.3398Z"
                fill="#171E21"
                fillOpacity="0.2"
            />
        </svg>

    )
}

//@todo Replace svg with import
function IconReset() {
    return (
        <svg
            className={st.iconReset}
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.70711 4.29289C5.31658 3.90237 4.68342 3.90237 4.29289 4.29289C3.90237 4.68342 3.90237 5.31658 4.29289 5.70711L10.5838 11.998L4.29303 18.2888C3.90251 18.6794 3.90251 19.3125 4.29303 19.7031C4.68356 20.0936 5.31672 20.0936 5.70725 19.7031L11.998 13.4123L18.2888 19.7031C18.6794 20.0936 19.3125 20.0936 19.7031 19.7031C20.0936 19.3125 20.0936 18.6794 19.7031 18.2888L13.4123 11.998L19.7032 5.70711C20.0937 5.31658 20.0937 4.68342 19.7032 4.29289C19.3127 3.90237 18.6795 3.90237 18.289 4.29289L11.998 10.5838L5.70711 4.29289Z"
                fill="#171E21"
                fillOpacity="0.2"
            />
        </svg>
    )
}