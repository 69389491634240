import React, { useMemo } from 'react'
import Image from 'next/image'
import moment from 'moment'
import star from '../public/img/star.svg'
import video from '../public/img/video.svg'
import star_active from '../public/img/star-active.svg'
import Incident from './Incindent'
import { useTranslation } from 'react-i18next'
import NoSsr from './NoSsr'
import Link from 'next/link'
import clsx from 'clsx';
import {checkWinScore} from '../utils';
import {ElementAnimated} from './animation';
import { Logo } from './logo'
import { PopoverOrDrawer } from './ui'
import { BestOffersPopup } from './BestOffersPopup'

type Props = {
    competition: any
    match: any
    index: number
    onClickMatch?: Function
    odds_selects: any
    favorites: any
    _onClickFavorite: Function,
    oddType?: any,
}

export default function MatchRow({
    competition,
    match,
    index,
    onClickMatch,
    odds_selects,
    favorites,
    _onClickFavorite,
    oddType,
}: Props) {
    const today = moment(new Date())
    const dateStart = moment(match.date_start * 1000)

    const { t } = useTranslation('common')

    function getColTitleTime(match: any, type: 'desktop' | 'mobile') {
        if (match.live || match.score.livetime) {
            return type === 'mobile'
                ? match.score.livetime[1]
                : match.score.livetime[0]
        }

        // check upcoming match
        return today < dateStart
            ? dateStart.format('H:mm')
            : ''
    }
    const getColTitleTimeDesktop = useMemo(
        () => getColTitleTime(match, 'desktop'),
        [dateStart]
    )
    const getColTitleTimeMobile = useMemo(
        () => getColTitleTime(match, 'mobile'),
        [dateStart]
    )

    const noMatchData = useMemo<boolean>(() => {
        return today > dateStart && match?.score?.result == null
    }, [dateStart, match?.score?.result])

    return (
        <Link
            href={match.url}
            className={clsx(
                'table__row table__row--match',
                competition.matches.length - 1 == index && 'table__row--match-last',
                today < dateStart && 'table__row--match-upcoming'
            )}
            onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                onClickMatch(match.id, match.live, match.url)
            }}
        >
            <div className='flex flex-align grid grid--4 jc'>
                <div className='flex flex-align'>
                    <div className='table__col table__col--time'>
                        <span
                            className={clsx(
                                'table__text show-desktop',
                                match.live && 'table__text--red table__text--bold'
                            )}
                        >
                            <NoSsr>{getColTitleTimeDesktop}</NoSsr>
                        </span>
                        <span
                            className={clsx(
                                'table__text show-mobile',
                                match.live && 'table__text--red table__text--bold'
                            )}
                        >
                            <NoSsr>{getColTitleTimeMobile}</NoSsr>
                        </span>
                    </div>
                    <div className='table__col table__col--team grid grid--4'>
                        <div className='team-wrapper'>
                            {!match.team1.logo && (
                                <div className='team-ico preloader__block preloader__block--team preloader__block--team-circle' />
                            )}
                            {match.team1.logo && (
                                <Logo
                                    type='teams'
                                    size={40}
                                    dimension={20}
                                    logoId={match.team1.logo}
                                    alt={match.team1.title}
                                />
                            )}
                            <span className='team-title'>
                                {match.team1.title}
                            </span>
                            <span className='team-title--mobile'>
                                {match.team1.short}
                            </span>
                            {match?.incidents[0]?.map(
                                (incident: any, index: number) => (
                                    <Incident
                                        key={index}
                                        incindent={incident}
                                        nextIncident={match?.incidents[0][index + 1]}
                                        isLive={match.live}
                                    />
                                )
                            )}
                        </div>
                        <div className='team-wrapper'>
                            {!match.team2.logo && (
                                <div className='team-ico preloader__block preloader__block--team preloader__block--team-circle' />
                            )}
                            {match.team2.logo && (
                                <Logo
                                    type='teams'
                                    size={40}
                                    dimension={20}
                                    logoId={match.team2.logo}
                                    alt={match.team2.title}
                                />
                            )}
                            <span className='team-title'>
                                {match.team2.title}
                            </span>
                            <span className='team-title team-title--mobile'>
                                {match.team2.short}
                            </span>
                            {match?.incidents[1]?.map(
                                (incident: any, index: number) => (
                                    <Incident
                                        key={`competition-${competition.id}-${match.id}-incident-2-key-${index}`}
                                        incindent={incident}
                                        nextIncident={
                                            match?.incidents[1][index + 1]
                                        }
                                    />
                                )
                            )}
                        </div>
                    </div>
                </div>
                <div className='flex flex-align'>
                    {match.tip && (
                        <div className='table__col table__col--forecast'>
                            <div className={`badge badge--forecast-table badge--forecast-${match.tip}`}>
                                {t('global.tip')}
                            </div>
                        </div>
                    )}
                    <div className='table__col table__col--result'>
                        {/* no result */}
                        {match?.score?.result == null && [0, 1].map((item, index: number) => (
                            <div
                                className={'table__block table__block--result'}
                                key={index}
                            >
                                <span className={clsx('table__block-title', match.live && 'table__block-title--red')}>
                                    –
                                </span>
                                {noMatchData && (
                                    <div className='tooltip tooltip--kf'>
                                        <span className='tooltip__title'>
                                            Нет данных по матчу
                                        </span>
                                    </div>
                                )}
                            </div>
                        ))}
                        {match?.score?.result?.map((block: any, index: number) => (
                            <div
                                className={clsx(
                                    'table__block table__block--result',
                                    !block && block != 0 && 'table__block--result-no',
                                )}
                                key={index}
                            >
                                <ElementAnimated
                                    value={block || block == 0
                                        ? block as string
                                        : match.live
                                            ? '0'
                                            : '–'
                                    }
                                    animation='animationPulse'
                                    animateCondition={match.live && checkWinScore(match?.score?.result, index)}
                                    renderElement={(
                                        ref,
                                        value,
                                        cls,
                                    ) => (
                                        <span
                                            ref={ref}
                                            className={clsx(
                                                'table__block-title',
                                                block || block == 0 &&
                                                    !match.live && moment() > dateStart && 'table__block-title--black',
                                                match.live && 'table__block-title--red',
                                                checkWinScore(match?.score?.result, index) && 'table__block-title--strong',
                                                cls,
                                            )}
                                        >
                                            {value}
                                        </span>
                                    )}
                                />
                            </div>
                        )
                        )}
                    </div>
                    <NoSsr>
                        <div className='table__col table__col--empty table__col--favorite'>
                            {!match.live && today < dateStart && (
                                <button
                                    onClick={(e) => {
                                        e.preventDefault()
                                        e.stopPropagation()
                                        _onClickFavorite(e, 'match', match.id)
                                    }}
                                    className={clsx(
                                        'button button--view button--view-add',
                                        favorites.match.indexOf(match.id) !== -1 && 'button--active-favorite'
                                    )}
                                >
                                    <Image
                                        src={
                                            favorites.match.indexOf(match.id) !== -1
                                                ? star_active
                                                : star
                                        }
                                        className='button__ico button__ico--view'
                                        alt='star'
                                        width={16}
                                        height={16}
                                    />
                                </button>
                            )}
                        </div>
                    </NoSsr>
                </div>
            </div>
            {/* Stop click propagation -> prevent parent link click */}
            <div
                className='flex grid grid--4 table__right'
                onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                }}
            >
                {match.media ? (
                    <button className='button button--view'>
                        <Image
                            src={video}
                            className='button__ico button__ico--view'
                            alt='video'
                            width={16}
                            height={16}
                        />
                    </button>
                ) : (
                    <div
                        className={'wrapper-ico-video'}
                        style={{width: 24, height: 24}}
                    />
                )}
                {match.odds === null && (
                    <p className='no-bets-title'>Нет данных о ставках</p>
                )}
                {odds_selects.map((odd: any, index: number) => (
                    <PopoverOrDrawer
                        key={index}
                        title='лучшие предложения'
                        content={(
                            <BestOffersPopup
                                matchId={match.id}
                                oddType={oddType}
                                oddSelect={odds_selects[index]?.odd_select}
                                period={null}
                                handicap={oddType === 'totals' ? Number(match.odds[0].display) : null}
                            />
                        )}
                    >
                        <button
                            key={index}
                            className={clsx(
                                'bet-wrapper',
                                !match.live && today > dateStart && 'bet-wrapper--finished',
                                match.odds === null && 'bet-wrapper--hidden',
                                match.odds && match.odds[index]?.change && `bet-wrapper--${match.odds[index]?.change}`,
                                odd.odd_select === false && 'bet-wrapper--total',
                            )}
                            disabled={!match.live && today > dateStart}
                        >
                            <span
                                className={clsx(
                                    'bet-title',
                                    !match.live && today > dateStart && 'bet-title--finished',
                                    match.odds && match.odds[index]?.won && 'bet-title--won',
                                    !match.odds && 'bet-title--gray',
                                )}
                            >
                                {match.odds ? match.odds[index]?.display : '–'}
                            </span>
                        </button>
                    </PopoverOrDrawer>
                ))}
            </div>
        </Link>
    )
}